import React from 'react'
import Field from '../common/Field'
import I18n from 'i18n'
import { durationFromISOString } from '../../../../common/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TimeAgoElement } from '../../../../common/TimeAgo'
import { Link } from 'react-router-dom'
import Routes from '../../../../common/Routes'

const WeFitterDefaultFlavour = (props) => {
  const icon = props.icon
  const metadatum = props.metadatum

  const activityValue = (activity, dataRow) => {
    switch (activity) {
      case 'heart_rate_avg':
        return dataRow.heart_rate_summary?.average
      case 'sleep_duration':
        return durationFromISOString(dataRow.sleep_summary?.duration)
      default:
        return dataRow[activity]
    }
  }

  const weFitterActivitiesSummary = (dataRow) => {
    const activities = [
      'steps',
      'calories',
      'heart_rate_avg',
      'sleep_duration'
    ]
    return activities.map((activity) => {
      const value = activityValue(activity, dataRow)
      const title = I18n.t(`components.timeline.card.${activity}`)

      return <Field key={activity} title={title} value={value} />
    })
  }

  return (
    <>
      <div className='timeline-compact-wrapper'>
        {icon && (
          <div className='timeline-icon-compact center-align'>
            <FontAwesomeIcon icon={icon} />
          </div>
        )}
        <div style={{ width: '100%' }}>
          {metadatum && (
            <div className='timeline-compact-title-wrapper'>
              <Link
                to={Routes.data.showFn(
                  metadatum && metadatum.versioned_data_object_id
                )}
              >
                <div className='text-l text-heavy text-primary-color pointer from-group'>
                  {(metadatum && (metadatum.title || metadatum.filename)) ||
                    I18n.t('components.data.untitled_file')}
                </div>
              </Link>
              <p className='timeline-profile-details text-s text-muted'>
                <TimeAgoElement date={new Date(metadatum.event_start)} />
              </p>
            </div>
          )}
          {props.sdo.data_rows && (
            <div className='sport-fields'>
              {weFitterActivitiesSummary(
                props.sdo.data_rows[props.sdo.data_rows.length - 1]
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default WeFitterDefaultFlavour
