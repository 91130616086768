import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useFetchData } from '../../common/Hooks'
import TrainingDTO from '../../common/types/DTOs/TrainingDTO'
import Card from '../../atomic/atoms/Card'
import Header from '../../atomic/atoms/headers/Header'
import I18n from 'i18n'
import moment from 'moment'
import StartAndEndDate from '../../atomic/molecules/StartAndEndDate'
import { useLocation } from 'react-router-dom'
// import { SelectMultiple } from '../../common/form'
import { uniqBy, flatten } from 'lodash'
import Training from '../../common/types/Training'
import TrainingLoadPerWeek from 'components/atomic/organisms/TrainingLoadPerWeekGraph'
import SelectDropdown from 'components/common/SelectDropdown'

// Provided optional history state if navigating to this page from the calendar view
export interface State {
  onDate?: string
}

const TrainingSchedulesCoachView: React.FC = () => {
  const { onDate } = useLocation<State>().state ?? {}
  // Holds the ids of the selected athlete, if it's 0 it won't retrieve expected load
  const [selectedAthleteId, setSelectedAthleteId] = useState<number>(0)

  let initialStartDate = moment().subtract(1, 'month').startOf('day').toDate()
  let initialEndDate = moment().add(1, 'month').endOf('day').toDate()
  if (onDate !== undefined) {
    initialStartDate = moment(onDate).startOf('day').toDate()
    initialEndDate = moment(onDate).endOf('day').toDate()
  }

  const [startDate, setStartDate] = useState<Date>(initialStartDate)
  const [endDate, setEndDate] = useState<Date>(initialEndDate)
  // Used to trigger an update

  const fetchingParams = { start_date: startDate.toISOString(), end_date: endDate.toISOString() }
  const { data: schedules = [] as TrainingDTO[], reFetch: reFetchSchedules } = useFetchData('/api/v1/schedules', fetchingParams)

  const firstUpdate = useRef<boolean>(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    reFetchSchedules(fetchingParams)
  }, [startDate, endDate])

  const schedulesWithFixedDates: Training[] = useMemo(() => {
    return [...schedules.map(schedule => { return { ...schedule, start_date: new Date(schedule.start_date) } })]
  }, [schedules])

  const athletes = uniqBy(flatten(schedulesWithFixedDates.map(training => training.athletes)), 'id')

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <Card>
            <Header isTopHeader>{I18n.t('components.trainings.planned_periodization')}</Header>
            <div className='row'>
              <StartAndEndDate startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} sizeClass='s4' />
              <div className='col s4'>
                <SelectDropdown
                  id='athlete'
                  content={athletes.map(athlete => ({
                    name: `${athlete.first_name ?? ''} ${athlete.last_name ?? ''}`,
                    value: athlete.id
                  }))}
                  label={I18n.t('components.trainings.dropdown.athlete')}
                  value={selectedAthleteId === 0 ? undefined : selectedAthleteId}
                  placeholder={I18n.t('components.trainings.dropdown.athlete_placeholder')}
                  onChange={e => setSelectedAthleteId(e?.target?.value !== undefined ? e.target.value : 0)}
                />
              </div>
            </div>
            <div className='row questionnaire-dashboard-wrapper'>
              <div className='col s12'>
                <div className='chart-container'>
                  <TrainingLoadPerWeek start={startDate} end={endDate} profileId={selectedAthleteId} />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}

export interface UpdateTrainingProps<T> {
  updateTraining: (updateCallbackSuccess: () => void, updateCallbackError: () => void, training: T, rowIndex: number | null) => void
  refetch: () => void
}

export default TrainingSchedulesCoachView
