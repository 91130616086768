import React from 'react'
import I18n from 'i18n'
import { WithModal } from '../../modal/ModalProvider'
import { WithSession } from '../../session/SessionProvider'
import { WithQuestionnaires } from '../../questionnaires/QuestionnaireProvider'
import { Link } from 'react-router-dom'
import Routes from '../../common/Routes'
import QuestionnairesButton from '../../common/QuestionnairesButton'
import ScheduleTrainingButton from '../../common/ScheduleTrainingButton'
import TrainingsButton from '../../common/TrainingsButton'
import FeatureFlag, { TRAINING_SCHEDULER_ATHLETE, TRAINING_SCHEDULER_COACH } from '../../feature_flags/FeatureFlag'
import WithProfileMetadata from '../../helpers/WithProfileMetadata'
// import TrainingLogButton from '../../common/TrainingLogButton'

class Welcome extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      trainingLogDisabled: false
    }
  }

  componentDidMount () {
    this.setState({ trainingLogDisabled: false })
  }

  handleClick (e) {
    if (this.state.trainingLogDisabled) {
      e.preventDefault()
      return
    }
    this.setState({ trainingLogDisabled: true })
  }

  render () {
    const { myProfile, myProfileMetadata, ModalButton, modalButtonProps } = this.props
    /* eslint-disable camelcase */
    /* follow-enable (remove comment here, and remove the next line) */
    // const { own_dataset_count, connection_count, follower_count, first_name, last_name } = myProfile
    const first_name = myProfile?.first_name
    const last_name = myProfile?.last_name
    const { own_dataset_count, connection_count } = myProfileMetadata
    let { welcomeMessage, profileLink, onPictureClick } = this.props
    welcomeMessage = welcomeMessage || I18n.t('components.welcome.label.welcome_back')
    profileLink = profileLink || (this.props.myProfile?.slug && Routes.profiles.showFn(this.props.myProfile?.slug)) || '#'

    const hasOpenResponses = this.props.questionnaires.areEnabled && this.props.questionnaires.myOpenResponses.length > 0
    const showQuestionnairesButton = !this.props.hideTrainingLog

    return (
      <>
        <div className='welcome-container top-rounded background-background' />
        <div className='welcome-container welcome-content background-background'>
          <div className='row profile-header center tall-lines'>
            <div className='text-m text-light welcome-back text-muted'>{welcomeMessage}</div>
            <Link to={profileLink}>
              <div className='text-m text-heavy'>{`${first_name || ''} ${last_name || ''}`}</div>
            </Link>
          </div>
          <div className='row center' style={{ marginTop: '0.5rem' }}>
            <div className='col s4 m4 l4' />
            <div className='col s4 m4 l4 profile-picture'>
              <img onClick={() => onPictureClick(profileLink)} className='circle responsive-img pointer' src={myProfile?.picture || '/images/pfp-placeholder.png'} />
            </div>
          </div>
          <div className='row profile-info-row text-s'>
            <div className='col s6 text-s'>
              <div className='center-align text-light text-muted'>
                {I18n.t('components.welcome.label.connections')}
              </div>
              <div className='center-align text-heavy'>
                {connection_count || '0'}
              </div>
            </div>
            {/* follow-enable (remove comment here) */}
            {/*
            <div className='col s4 text-s'>
              <div className='center-align text-light text-muted'>
                {I18n.t('components.welcome.label.followers')}
              </div>
              <div className='center-align text-heavy'>
                {follower_count || '0'}
              </div>
            </div> */}
            <div className='col s6 text-s'>
              <div className='center-align text-light text-muted'>
                {I18n.t('components.welcome.label.datasets')}
              </div>
              <div className='center-align text-heavy'>
                {own_dataset_count || '0'}
              </div>
            </div>
          </div>
          <div className='row button-row'>
            {modalButtonProps && (
              <div className='col s6 m12'>
                <ModalButton {...modalButtonProps} />
              </div>
            )}
            {showQuestionnairesButton && <QuestionnairesButton hasOpenResponses={hasOpenResponses} />}
            {/* <TrainingLogButton hideTrainingLog={this.props.hideTrainingLog} withContainer /> */}
            <FeatureFlag name={TRAINING_SCHEDULER_ATHLETE}>
              <TrainingsButton />
            </FeatureFlag>
            <FeatureFlag name={TRAINING_SCHEDULER_COACH}>
              <ScheduleTrainingButton />
            </FeatureFlag>
          </div>
        </div>
      </>
    )
    /* eslint-enable camelcase */
  }
}

export default WithQuestionnaires(WithSession(WithProfileMetadata(WithModal(Welcome))))
