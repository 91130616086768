import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react'
import I18n from 'i18n'
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor'
import Search from '../../../network/layout/Search'
import ProfileGroupResult from '../../../network/layout/Search/ProfileGroupResult'
import { MyAxios as axios } from '../../../MyAxios'
import { WithSession } from '../../../session/SessionProvider'
import AutocompleteProfileDTO from '../../../common/types/DTOs/AutocompleteProfileDTO'
import ManageProfiles from '../../../atomic/molecules/ManageProfiles'
import Header from '../../../atomic/atoms/headers/Header'

interface SessionProps {
  sessionToken: string
}

const AthletesEditor = forwardRef((props: ICellEditorParams & SessionProps, ref) => {
  const [athletes, setAthletes] = useState<AutocompleteProfileDTO[]>(props.value ?? [])
  const searchRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // focus on the input
    searchRef.current?.focus()
  }, [])

  useEffect(() => {
    if (athletes.length > props.value.length) {
      props.stopEditing()
    }
  }, [athletes])

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue () {
        return athletes
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart () {
        return false
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd () {
        // Athletes are required
        return athletes === null || athletes === undefined
      }
    }
  })

  const onSearch = async (event: any/*, refetch?: any */): Promise<AutocompleteProfileDTO[]> => {
    const response = await axios({
      method: 'GET',
      url: `/api/v1/profiles/search_connections?q=${encodeURIComponent(event.target.value)}&include_connected_through_groups=true&include_self=true`,
      headers: { Authorization: props.sessionToken }
    })

    return response.data.filter((profile: AutocompleteProfileDTO) => !athletes.map(athlete => athlete.id).includes(profile.id))
  }

  const onSendInvite = (profile: AutocompleteProfileDTO): void => {
    setAthletes([...athletes.filter(curProfile => curProfile.id !== profile.id), profile])
  }

  return (
    <div style={{ minHeight: '300px' }}>
      <div className='row' style={{ marginBottom: 0 }}>
        <div className='col s12'>
          <div className='padding-wrapper'>
            <Header isTopHeader>{I18n.t('components.trainings.athletes')}</Header>
          </div>
          <ManageProfiles profiles={athletes} setProfiles={setAthletes} />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <Search
            fieldId='group-detail-members'
            Component={ProfileGroupResult}
            onSearch={onSearch}
            label={I18n.t('components.trainings.dropdown.athletes_placeholder')}
            buttonText={I18n.t('components.trainings.dropdown.add_athlete')}
            onClick={onSendInvite}
            inputRef={searchRef}
          />
        </div>
      </div>
    </div>
  )
})

export default WithSession(AthletesEditor) as typeof AthletesEditor
