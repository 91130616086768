import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useFetchData } from '../../common/Hooks'
import { useTraining } from '../hooks/trainingHooks'
import TrainingDTO from '../../common/types/DTOs/TrainingDTO'
import Card from '../../atomic/atoms/Card'
import Header from '../../atomic/atoms/headers/Header'
import I18n from 'i18n'
import NewTrainingSessions from './NewTrainingSessions'
import ScheduledTrainingSessions from './ScheduledTrainingSessions'
import NewTraining from '../../common/types/NewTraining'
import moment from 'moment'
import StartAndEndDate from '../../atomic/molecules/StartAndEndDate'
import { useLocation } from 'react-router-dom'
import { SelectMultiple } from '../../common/form'
import { uniqBy, flatten, intersection } from 'lodash'
import Training from '../../common/types/Training'

// Provided optional history state if navigating to this page from the calendar view
export interface State {
  onDate?: string
}

const TrainingSchedulesCoachView: React.FC = () => {
  const { createTraining, destroyTraining, updateTraining } = useTraining()
  const { onDate } = useLocation<State>().state ?? {}
  // Holds the ids of the selected athletes
  const [selectedAthleteIds, setSelectedAthleteIds] = useState<number[]>([])

  let initialStartDate = moment().subtract(1, 'month').startOf('day').toDate()
  let initialEndDate = moment().add(1, 'month').endOf('day').toDate()
  if (onDate !== undefined) {
    initialStartDate = moment(onDate).startOf('day').toDate()
    initialEndDate = moment(onDate).endOf('day').toDate()
  }

  const [startDate, setStartDate] = useState<Date>(initialStartDate)
  const [endDate, setEndDate] = useState<Date>(initialEndDate)
  // Used to trigger an update
  const [update, setUpdate] = useState<boolean>(false)

  const fetchingParams = { start_date: startDate.toISOString(), end_date: endDate.toISOString() }
  const { data: schedules = [] as TrainingDTO[], reFetch: reFetchSchedules } = useFetchData('/api/v1/schedules', fetchingParams)

  const firstUpdate = useRef<boolean>(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    reFetchSchedules(fetchingParams)
  }, [startDate, endDate])

  const schedulesWithFixedDates: Training[] = useMemo(() => {
    return [...schedules.map(schedule => { return { ...schedule, start_date: new Date(schedule.start_date) } })]
  }, [schedules])

  function handleDeleteTraining (id: number): void {
    destroyTraining(id, () => reFetchSchedules(fetchingParams))
  }

  const handleCreateTraining = (newTrainings: NewTraining[], onTrainingCreated: (createdTraining: NewTraining) => void): void => {
    for (const newTraining of newTrainings) {
      createTraining(newTraining, () => handleTrainingCreated(newTraining, onTrainingCreated))
    }
  }

  const handleTrainingCreated = (createdTraining: NewTraining, onTrainingCreated: (createdTraining: NewTraining) => void): void => {
    onTrainingCreated(createdTraining)
    reFetchSchedules(fetchingParams)
  }

  const athletes = uniqBy(flatten(schedulesWithFixedDates.map(training => training.athletes)), 'id')

  let parsedSchedules = schedulesWithFixedDates
  if (selectedAthleteIds.length > 0) {
    parsedSchedules = schedulesWithFixedDates.filter(training => intersection(selectedAthleteIds, training.athletes.map(athlete => athlete.id)).length > 0)
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <Card>
            <Header isTopHeader>{I18n.t('components.trainings.preview_schedule_title')}</Header>
            <div className='row'>
              <StartAndEndDate startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} sizeClass='s4' />
              <div className='col s4'>
                <SelectMultiple
                  id='athletes'
                  formName='components.dashboards'
                  component='multi_athlete'
                  type='select_multiple'
                  fieldName='athletes'
                  currentValue={selectedAthleteIds.map(value => `${value}`)}
                  choices={athletes.map(athlete => {
                    return {
                      properties: {
                        name: `${athlete.first_name ?? ''} ${athlete.last_name ?? ''}`,
                        value: athlete.id
                      }
                    }
                  })}
                  onChange={(e: any) => setSelectedAthleteIds(e.getSelectedValues().map((value: string) => parseInt(value)))}
                  selectAll={() => setSelectedAthleteIds(athletes.map(athlete => athlete.id))}
                  selectNone={() => setSelectedAthleteIds([])}
                  noWrapper
                  noTranslate
                />
              </div>
            </div>
            <ScheduledTrainingSessions
              schedules={parsedSchedules}
              onDelete={handleDeleteTraining}
              updateTrainingProps={{ updateTraining: updateTraining, refetch: () => reFetchSchedules(fetchingParams) }}
              toggleUpdate={() => setUpdate(!update)}
            />
          </Card>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <Card>
            <NewTrainingSessions
              onCreate={handleCreateTraining}
              update={update}
            />
          </Card>
        </div>
      </div>
    </>
  )
}

export interface UpdateTrainingProps<T> {
  updateTraining: (updateCallbackSuccess: () => void, updateCallbackError: () => void, training: T, rowIndex: number | null) => void
  refetch: () => void
}

export default TrainingSchedulesCoachView
