import React, { useContext, CSSProperties } from 'react'
import I18n from 'i18n'
import GroupDTO from '../../common/types/DTOs/GroupDTO'
import { regularGroups as RegularGroups } from '../../common/Constants'
import { filterActiveConnections } from '../../common/Utils'
import GroupMembershipDTO from '../../common/types/DTOs/GroupMembershipDTO'
import { SessionContext } from '../../session/SessionProvider'
import { uniqBy, flatten, uniq } from 'lodash'
import Select from 'react-select'

interface Props {
  groups: GroupDTO[]
  selectedProfileIds: number[]
  setSelectedProfileIds: (selectedProfileIds: number[]) => void
  placeholder?: string
}

// This component takes a groups attribute, which should be the direct result of calling the
// api/v1/groups route, to receive a list of connections and groups that a person is in. It uses these
// groups and connections to render a select dropdown that allows selecting individual profiles
// that exist in one of the person's groups or connections. The dropdown is rendered with profile images
// and supports group selection (by clicking the group header), autocompletion, typing, and so on.
// It removes results that are already included from the list and stays open after selection so that
// it is easy to select multiple profiles or entire groups at once.
const GroupMembersSelect: React.FC<Props> = ({ groups, selectedProfileIds, setSelectedProfileIds, placeholder = I18n.t('molecules.group_members_select.select_athletes') }) => {
  const session = useContext(SessionContext)
  const myProfileId: number | undefined = session.myProfile?.id

  let regularGroups: GroupDTO[] = []
  let connections: GroupMembershipDTO[] = []
  if (myProfileId !== undefined) {
    regularGroups = groups.filter((group) => RegularGroups.includes(group.group_type))
    connections = filterActiveConnections(groups).map((group: GroupDTO) => theOtherGroupMembership(group, myProfileId)).filter((groupMembership: GroupMembershipDTO | undefined) => groupMembership !== undefined)
  }

  const groupedOptions: GroupedOption[] = []
  for (const group of regularGroups) {
    groupedOptions.push({
      id: group.id,
      label: group.name,
      icon: group.picture,
      options: groupAthletes(group)
    })
  }
  groupedOptions.push({
    id: -1,
    label: I18n.t('datasharing.labels.connections'),
    icon: '',
    options: connectionAthletes(connections)
  })

  const setSelectedAthletes = (selectedAthletes: readonly AthleteOption[]): void => {
    setSelectedProfileIds(selectedAthletes.map(selectedAthlete => parseInt(selectedAthlete.value)))
  }

  const groupClicked = (e: React.MouseEvent<HTMLDivElement>): void => {
    const dataValue = e.currentTarget.getAttribute('data-value')
    if (dataValue === null) return

    const clickedGroupId = parseInt(dataValue)
    if (clickedGroupId === -1) {
      // select connections
      const connectionProfileIds: number[] = connections.map(connection => connection.profile.id)
      setSelectedProfileIds(uniq([...selectedProfileIds, ...connectionProfileIds]))
    } else {
      // regular group
      const clickedGroup = regularGroups.find(regularGroup => regularGroup.id === clickedGroupId)
      if (clickedGroup === undefined) return

      const acceptedGroupMemberships = clickedGroup.group_memberships.filter(groupMembership => groupMembership.state === 'accepted')
      const groupMembershipProfileIds: number[] = acceptedGroupMemberships.map(acceptedGroupMembership => acceptedGroupMembership.profile.id)
      setSelectedProfileIds(uniq([...selectedProfileIds, ...groupMembershipProfileIds]))
    }
  }

  const formatGroupLabel = (data: GroupedOption): React.ReactElement => (
    <div
      style={groupStyles}
      onClick={groupClicked}
      data-value={data.id}
      className='multi-select-group'
      title={I18n.t('molecules.group_members_select.select_entire_group')}
    >
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
      {data.icon !== '' && <img style={groupPictureStyles} src={data.icon} alt={data.label} />}
    </div>
  )

  const athleteOptions = makeAthleteOptions(selectedProfileIds, groupedOptions)

  // Note that we provide the `Select` component from `react-select` with three arguments.
  // The first one is the interface in which `Option`s are to be provided.
  // The second argument is `IsMulti`, which extends boolean (in our case, `true`, since we allow for selecting
  // multiple athletes.
  // The third argument is the `Group` type, which is to specify in which format the Group as a whole
  // should be provided. By default it only includes the athleteOptions, but we require that it has a label, icon, and
  // id as well.
  return (
    <Select<AthleteOption, true, GroupedOption>
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      isMulti
      closeMenuOnSelect={false}
      className='basic-multi-select'
      defaultValue={athleteOptions}
      value={athleteOptions}
      onChange={setSelectedAthletes}
      placeholder={placeholder}
    />
  )
}

// Precondition: group.group_type === 'mutual_connection'
const theOtherGroupMembership = (group: GroupDTO, myProfileId: number): GroupMembershipDTO | undefined => {
  return group.group_memberships.find(groupMembership => groupMembership.profile.id !== myProfileId)
}

interface AthleteOption {
  // the profile id of the athlete
  readonly value: string
  readonly label: string
  readonly icon: string
}

interface GroupedOption {
  // the id of the group or -1 for the connections group
  readonly id: number
  readonly label: string
  readonly icon: string
  readonly options: AthleteOption[]
}

const groupStyles: CSSProperties = {
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between'
}

const athleteStyles: CSSProperties = {
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between'
}

const groupPictureStyles: CSSProperties = {
  position: 'absolute',
  right: 0,
  height: '20px',
  width: '20px',
  margin: '5px 50px 5px 15px',
  float: 'right'
}

const athletePictureStyles: CSSProperties = {
  position: 'absolute',
  left: 0,
  height: '20px',
  width: '20px',
  margin: '5px 15px 5px 0px',
  float: 'left'
}

const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center'
}

const optionTextStyles: CSSProperties = {
  marginLeft: '30px'
}

const makeAthleteOptions = (selectedProfileIds: number[], groupedOptions: GroupedOption[]): AthleteOption[] => {
  const athleteOptions = uniqBy(flatten(groupedOptions.map(groupedOption => groupedOption.options)), 'value')
  const result: AthleteOption[] = []
  // A bit inefficient, but the result should reflect the ordering of the ids in selectedProfileIds. So we need
  // to iterate over that.
  for (const selectedProfileId of selectedProfileIds) {
    const athleteOption = athleteOptions.find(athleteOption => parseInt(athleteOption.value) === selectedProfileId)
    if (athleteOption !== undefined) {
      result.push(athleteOption)
    }
  }
  return result
}

const groupAthletes = (group: GroupDTO): AthleteOption[] => {
  const result: AthleteOption[] = []
  const acceptedGroupMemberships = group.group_memberships.filter(groupMembership => groupMembership.state === 'accepted')
  for (const acceptedGroupMembership of acceptedGroupMemberships) {
    const membershipProfile = acceptedGroupMembership.profile
    result.push({
      value: `${membershipProfile.id}`,
      label: `${membershipProfile.first_name ?? ''} ${membershipProfile.last_name ?? ''}`,
      icon: membershipProfile.picture
    })
  }
  return result
}

const connectionAthletes = (connections: GroupMembershipDTO[]): AthleteOption[] => {
  const result: AthleteOption[] = []
  for (const connection of connections) {
    const connectionProfile = connection.profile
    result.push({
      value: `${connectionProfile.id}`,
      label: `${connectionProfile.first_name ?? ''} ${connectionProfile.last_name ?? ''}`,
      icon: connectionProfile.picture
    })
  }
  return result
}

const formatOptionLabel = (data: AthleteOption): React.ReactElement => (
  <div style={athleteStyles}>
    {data.icon !== '' && <img style={athletePictureStyles} src={data.icon} alt={data.label} />}
    <span style={optionTextStyles}>{data.label}</span>
  </div>
)

export default GroupMembersSelect
