import React from 'react'
import { WithSession } from '../session/SessionProvider'
import IntegrationsForm from './layout/IntegrationsForm'
import FeedbackButtonPanel from '../common/FeedbackButtonPanel'
import FeedbackButton from '../common/FeedbackButton'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { WithModal } from '../modal/ModalProvider'
// import SwaggerClient from 'swagger-client'
import Swagger from 'swagger-client'
import { MyAxios as axios } from '../MyAxios'
import I18n from 'i18n'
import Routes from '../common/Routes'
import SpinnerWrapper from '../common/SpinnerWrapper'
import { WithBackend } from 'components/backend/BackendProvider'
import { MYLAPS_THIALF_KEY } from 'components/common/Constants'
import { WithFeatureFlag } from 'components/feature_flags/FeatureFlagProvider'
import { MYLAPS_THIALF } from 'components/feature_flags/FeatureFlag'
import WithHistory from 'components/common/WithHistory'

class IntegrationsController extends React.Component {
  constructor (props) {
    super(props)

    // Enable CORS and browser cookies
    Swagger.http.withCredentials = true

    this.client = new Swagger(process.env.INTEGRATIONS_URL + '/api-json')
    this.authHeader = {
      requestInterceptor: (req) => {
        req.headers.Authorization = this.props.sessionToken
        return req
      }
    }

    // The integration with MyLaps works outside of the svc-integrations flow, so we hardcode it here
    this.myLapsThialfIntegration = {
      key: MYLAPS_THIALF_KEY,
      iconUrl: '/images/logo-mylaps-thialf.svg',
      enabled: true,
      canRevoke: true
    }

    this.state = {
      available: [],
      active: {},
      redirect: false,
      fetching: false
    }
  }

  isRedirect () {
    return window.location.pathname.includes(Routes.addedIntegration.substring(1))
  }

  triggerInitialGathering () {
    if (!this.isRedirect()) return false

    const endpoint = '/api/v1/jobs/schedule_gathering'
    this.props.flashMessages.push(`${I18n.t('components.integrations.added')} ${I18n.t('components.integrations.delay')}`,
      this.props.flashMessages.duration.SHORT,
      this.props.flashMessages.levels.INFO)
    return axios.get(endpoint, { headers: { Authorization: this.props.sessionToken } })
  }

  fetchAvailable () {
    return this.client.then(client => (
      client.apis.integration_registration.get_integration_registration()
    ))
  }

  fetchEnabled () {
    return this.client.then(client => (
      client.apis.integration_registration.get_integration_registration_mine({}, this.authHeader)
        .then(response => (
          JSON.parse(response.data).map(e => e.key)
        ))
        .catch(e => {
          if (e.status === 404) {
            // User has no integrations yet
            return []
          } else {
            if (process.env.NODE_ENV !== 'test') console.log(e)
          }
        })

    ))
  }

  async hasTransponder () {
    try {
      const response = await this.props.backend.transponders.show()
      return response.status === 200
    } catch (e) {
      if (e.response?.status !== 404) {
        console.log(e)
        this.props.flashMessages.pushErrorMessage(I18n.t('components.transponder.add_form.errors.unexpected_retrieval'))
      }
      return false
    }
  }

  async handleTransponderRemoval () {
    try {
      await this.props.backend.transponders.delete()
      this.fetch()
    } catch (e) {
      if (e.response) {
        if (e.response.status !== 404) {
          console.log(e)
          this.props.flashMessages.pushErrorMessage(I18n.t('components.transponder.add_form.errors.unexpected_removal'))
        }
      } else if (e.request) {
        console.log(e.request)
        this.props.flashMessages.pushErrorMessage(I18n.t('components.transponder.add_form.errors.unexpected_removal'))
      } else {
        console.log(e)
        this.props.flashMessages.pushErrorMessage(I18n.t('components.transponder.add_form.errors.unexpected_removal'))
      }
    }
  }

  fetch () {
    this.setState({ fetching: true })
    this.triggerInitialGathering()
    const fetchArray = [this.fetchAvailable(), this.fetchEnabled()]
    const mylapsThialfEnabled = this.props.features?.[MYLAPS_THIALF]
    if (mylapsThialfEnabled) {
      fetchArray.push(this.hasTransponder())
    }
    return Promise.all(fetchArray).then((results) => {
      this.setState({ fetching: false })
      const [available, active, hasTransponder] = results
      const data = JSON.parse(available.data)
      const isActive = {}
      data.forEach(item => { isActive[item.key] = active && active.includes(item.key) })
      if (this.props.features?.[MYLAPS_THIALF]) {
        isActive[MYLAPS_THIALF_KEY] = hasTransponder
        data.push(this.myLapsThialfIntegration)
      }
      this.setState({ available: data, active: isActive })
    }
    ).catch(e => {
      if (process.env.NODE_ENV !== 'test') console.log(e)
    })
  }

  componentDidMount () {
    this.fetch()
  }

  handleServiceClick (service) {
    window.location = service.externalUrl + `&external_callback_url=${process.env.HOST_URL}${Routes.addedIntegration}`
  }

  handleAddTransponder () {
    this.props.history.push(Routes.transponder.add)
  }

  handleServiceRemoval (service) {
    this.client.then(client => {
      client.apis.integration_registration.delete_integration_registration_mine__key_({ key: service.key }).then(() => {
        this.fetch()
      })
    }).catch(e => {
      if (process.env.NODE_ENV !== 'test') console.error(e)
    })
  }

  onHandleOkButton () {
    this.props.hide()
    window.location = process.env.HOST_URL
  }

  render () {
    return (
      <>
        <SpinnerWrapper ready={!this.state.fetching}>
          <IntegrationsForm
            available={this.state.available}
            active={this.state.active}
            handleServiceClick={this.handleServiceClick.bind(this)}
            handleServiceRemoval={this.handleServiceRemoval.bind(this)}
            handleTransponderRemoval={this.handleTransponderRemoval.bind(this)}
            handleAddTransponder={this.handleAddTransponder.bind(this)}
          />
        </SpinnerWrapper>
        <p>{I18n.t('components.integrations.description')}</p>
        <FeedbackButtonPanel>
          <FeedbackButton body={I18n.t('components.integrations.feedback.body')} />
        </FeedbackButtonPanel>
        {this.isRedirect() &&
          <>
            <div className='row'>
              <div className='col s12'>
                <p><b>{I18n.t('components.integrations.added')}</b> </p>
                <p><em> {I18n.t('components.integrations.delay')} </em></p>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <button onClick={this.onHandleOkButton.bind(this)} id='return-from-integration-button' className='waves-effect waves-light text-primary-color background-primary button-primary text-background-color'>{I18n.t('components.data.close_after_upload')}</button>
              </div>
            </div>
          </>}
      </>
    )
  }
}

export default WithHistory(WithFeatureFlag(WithModal(WithFlashMessages(WithBackend(WithSession(IntegrationsController))))))
