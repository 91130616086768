import { useContext } from 'react'
import { BackendContext } from '../../backend/BackendProvider'
import { FlashMessageContext } from '../../flashmessages/FlashMessageProvider'
import I18n from 'i18n'
import { capitalizeFirstLetter, smartTranslate } from 'components/common/Utils'

export function useTraining () {
  const { backend } = useContext(BackendContext)
  const { flashMessages } = useContext(FlashMessageContext)

  function createTraining (training, createdCallback = () => {}) {
    backend.schedules.create(training).then(() => {
      flashMessages.push(I18n.t('components.trainings.creation_success'), flashMessages.duration.SHORT, flashMessages.levels.INFO)
      createdCallback()
    }).catch(e => {
      const data = e?.response?.data
      if (data?.errors?.[0].status === '400') {
        const errorTitles = Object.entries(data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        flashMessages.push(I18n.t('components.trainings.errors.invalid_training', { errors: `: ${errorTitles}` }), flashMessages.duration.LONG, flashMessages.levels.ERROR)
      } else {
        flashMessages.push(I18n.t('components.trainings.errors.creation_error'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
      }
    })
  }

  function destroyTraining (id, destroyCallback) {
    backend.schedules.destroy(id).then(() => {
      flashMessages.push(I18n.t('components.trainings.destruction_success'), flashMessages.duration.SHORT, flashMessages.levels.INFO)
      destroyCallback()
    }).catch(() => {
      flashMessages.push(I18n.t('components.trainings.errors.destruction_error'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
    })
  }

  function updateTraining (updateCallbackSuccess, updateCallbackError, training) {
    backend.schedules.update(training.id, training).then(() => {
      flashMessages.push(I18n.t('components.trainings.update_success'), flashMessages.duration.SHORT, flashMessages.levels.INFO)
      updateCallbackSuccess()
    }).catch(e => {
      const data = e?.response?.data
      if (data?.errors?.[0].status === '400') {
        const errorTitles = Object.entries(data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        flashMessages.push(I18n.t('components.trainings.errors.invalid_training', { errors: `: ${errorTitles}` }), flashMessages.duration.LONG, flashMessages.levels.ERROR)
      } else {
        flashMessages.push(I18n.t('components.trainings.errors.update_error'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
      }
      updateCallbackError()
    })
  }

  return { createTraining, destroyTraining, updateTraining }
}
