import React from 'react'
import { FeatureFlagContext } from './FeatureFlagProvider'

export const TEST_FEATURE_FLAG = 'test_feature_flag'
export const OTHER_FEATURE = 'OTHER_FEATURE'
export const BACKGROUND_JOBS_INFO_CARD = 'background_jobs_info_card'
export const TRAINING_SCHEDULER_ATHLETE = 'training_scheduler_athlete'
export const TRAINING_SCHEDULER_COACH = 'training_scheduler_coach'
export const CHANGE_EMAIL = 'change_email'
export const COACH_DASHBOARD = 'coach_dashboard_cic'
export const INFORMED_CONSENT = 'informed_consent'
export const SURFCONEXT = 'surfconext'
export const TOUR = 'tour'
export const QUESTIONNAIRES_AUTOMATIC_GROUP_JOIN_PROTOCOL = 'questionnaires-automatic_group_join_protocol'
export const WIELER_FITHEID = 'wielerfitheid'
export const EXECUTABLE_NOTEBOOKS = 'executable_notebooks'
export const MYLAPS_THIALF = 'mylaps_thialf'
export const OPTIMIZED_QUESTIONNAIRE_DASHBOARD = 'optimized_questionnaire_dashboard'
export const JUPYTERHUB = 'jupyterhub'

export const FEATURE_FLAG_OPTIONS = {
  features: [
    OTHER_FEATURE,
    TEST_FEATURE_FLAG,
    BACKGROUND_JOBS_INFO_CARD,
    TRAINING_SCHEDULER_ATHLETE,
    TRAINING_SCHEDULER_COACH,
    CHANGE_EMAIL,
    COACH_DASHBOARD,
    INFORMED_CONSENT,
    SURFCONEXT,
    TOUR,
    QUESTIONNAIRES_AUTOMATIC_GROUP_JOIN_PROTOCOL,
    WIELER_FITHEID,
    MYLAPS_THIALF,
    OPTIMIZED_QUESTIONNAIRE_DASHBOARD,
    JUPYTERHUB
  ]
}

const FeatureFlag = ({ name, children, defaultChildren, debug }) => (
  <FeatureFlagContext.Consumer>
    {({ features } = {}) => {
      if (features?.[name] || debug) {
        return children
      }
      return defaultChildren
    }}
  </FeatureFlagContext.Consumer>
)

FeatureFlag.defaultProps = {
  defaultChildren: <></>,
  debug: false
}

export default FeatureFlag
