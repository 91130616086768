import React from 'react'
import GroupMembers from '../../../network/layout/GroupMembers'

interface Props {
  valueFormatted?: string
  value: any
}

const AthletesRenderer: React.FC<Props> = ({ value }): React.ReactElement => {
  return (
    <GroupMembers
      memberships={value.reduce((acc: any[], v: any) => acc.concat({ id: v.id, profile: v }), [])}
      avatarOnly
      noComma
    />
  )
}

export default AthletesRenderer
