import React from 'react'
import I18n from 'i18n'

import DailyWellnessChart from '../questionnaire/full/DailyWellnessChart'
import QuestionnaireFeedbackTable from '../questionnaire/full/QuestionnaireFeedbackTable'
import TrainingAcuteChronicWorkloadRatioChart from '../questionnaire/full/TrainingAcuteChronicWorkloadRatioChart'
import TrainingSatisfactionChart from '../questionnaire/full/TrainingSatisfactionChart'
import { preprocessWellbeingQuestionnaires, preprocessTrainingLogs } from '../questionnaire/Utils'
import Info from 'components/atomic/atoms/Info'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import TrainingLoadPerWeekGraph from 'components/atomic/organisms/TrainingLoadPerWeekGraph'
import SpinnerWrapper from 'components/common/SpinnerWrapper'

const CoachTrendsSection = (props) => {
  const { trainingLogs, dailyQuestionnaires, start, end, athleteId, dataIsFetched } = props

  const trainingLogSDOs = trainingLogs?.map((dataset) => dataset?.structured_data_objects[0])
  const dailyQuestionnairesSDOs = dailyQuestionnaires?.map((dataset) => dataset?.structured_data_objects[0])

  let preprocessedTrainingLogs = {}
  if (trainingLogSDOs && trainingLogSDOs.length > 0) {
    preprocessedTrainingLogs = preprocessTrainingLogs(trainingLogSDOs)
  }

  let preprocessedDailyQuestionnaires = {}
  if (dailyQuestionnairesSDOs && dailyQuestionnairesSDOs.length > 0) {
    preprocessedDailyQuestionnaires = preprocessWellbeingQuestionnaires(dailyQuestionnairesSDOs)
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <div className='chart-container'>
            <TrainingLoadPerWeekGraph start={start} end={end} profileId={athleteId} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='chart-container'>
            <SpinnerWrapper ready={dataIsFetched}>
              <TrainingAcuteChronicWorkloadRatioChart weeklySummary={preprocessedTrainingLogs?.weeklySummary} />
            </SpinnerWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='chart-container'>
            <SpinnerWrapper ready={dataIsFetched}>
              <DailyWellnessChart
                dailyLogRows={preprocessedDailyQuestionnaires?.dailyLogRows}
                withSleepDuration
                withRestingHr
              />
            </SpinnerWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='chart-container'>
            <SpinnerWrapper ready={dataIsFetched}>
              <TrainingSatisfactionChart trainingLogRows={preprocessedTrainingLogs?.trainingLogRows} />
            </SpinnerWrapper>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='chart-container feedback'>
            <SpinnerWrapper ready={dataIsFetched}>
              {/* The code below is almost 100% copied from the WellbeingQuestionnaireDashboard. This should be extracted to avoid possible problems */}
              {!preprocessedTrainingLogs?.trainingLogRows && (
                <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.training_feedback.title')} />
              )}
              {preprocessedTrainingLogs?.trainingLogRows && (
                <>
                  <div className='highcharts-plot-title-container'>
                    <div className='highcharts-plot-title'>
                      {I18n.t('components.dashboards.questionnaire.training_feedback.title')}
                      <Info text={I18n.t('components.dashboards.questionnaire.training_feedback.info')} tooltipId='questionnaire-tooltip-training-feedback' inline />
                    </div>
                  </div>
                  <div className='feedback-table-container'>
                    <QuestionnaireFeedbackTable
                      id='training_sessions'
                      data={preprocessedTrainingLogs?.trainingLogRows}
                      columns={[
                        {
                          column: 'training_start_date',
                          label: I18n.t('components.dashboards.questionnaire.training_feedback.training_start_date'),
                          tooltip_column: 'formatted_training_start_date',
                          style: { minWidth: '75px' }
                        },
                        {
                          column: 'training_type',
                          label: I18n.t('components.dashboards.questionnaire.training_feedback.training_type'),
                          tooltip_column: 'training_type',
                          style: { minWidth: '77px' }
                        },
                        {
                          column: 'training_session_short',
                          label: I18n.t('components.dashboards.questionnaire.training_feedback.training_session_short'),
                          tooltip_column: 'training_session',
                          style: { minWidth: '77px' }
                        },
                        { column: 'rpe', label: I18n.t('components.dashboards.questionnaire.training_feedback.rpe') },
                        { column: 'training_duration', label: I18n.t('components.dashboards.questionnaire.training_feedback.training_duration') },
                        {
                          column: 'comments',
                          label: I18n.t('components.dashboards.questionnaire.training_feedback.comments')
                        }
                      ]}
                    />
                  </div>
                </>
              )}
            </SpinnerWrapper>
          </div>
        </div>
      </div>
    </>
  )
}

export default CoachTrendsSection
