import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import TrainingSessionsTable from './TrainingSessionsTable'
import { UpdateTrainingProps } from './TrainingSchedulesCoachView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../atomic/atoms/Button'
import DeleteButton from '../../atomic/atoms/DeleteButton'
import NewTraining from '../../common/types/NewTraining'
import { newTrainings, setNewTrainings } from './NewTrainingSessions'
import Training from '../../common/types/Training'

declare let crypto: any

interface Props {
  schedules: Training[]
  onDelete: (id: number) => void
  updateTrainingProps: UpdateTrainingProps<Training>
  toggleUpdate: () => void
  readOnly?: boolean
}

const ScheduledTrainingSessions: React.FC<Props> = ({
  schedules, updateTrainingProps, toggleUpdate, onDelete, readOnly = false
}) => {
  const [selectedTrainings, setSelectedTrainings] = useState<Training[]>([])

  // Reset the selection if the contents of the table changes
  useEffect(() => {
    setSelectedTrainings([])
  }, [schedules])

  const onSelectionChanged = (selectedRows: Training[]): void => {
    setSelectedTrainings(selectedRows)
  }

  const copySelectionToNewTrainingSessions = (): void => {
    // create an array of NewTraining objects from the selectedTrainings objects.
    // Make sure they have the correct properties and UUIDs
    // call a function that is given in props to add those to the new training sessions table as new rows
    const newerTrainings = [...newTrainings]
    for (const selectedTraining of selectedTrainings) {
      const newTraining: NewTraining = {
        start_date: selectedTraining.start_date,
        location: selectedTraining.location,
        trainer: selectedTraining.trainer,
        week_type: selectedTraining.week_type,
        training_type: selectedTraining.training_type,
        session_type: selectedTraining.session_type,
        expected_duration: selectedTraining.expected_duration,
        expected_rpe: selectedTraining.expected_rpe,
        athletes: selectedTraining.athletes.map(athlete => { return { ...athlete, slug: '' } }),
        warmup: selectedTraining.warmup,
        instructions: selectedTraining.instructions,
        instructions_is_link: selectedTraining.instructions_is_link,
        send_training_log: selectedTraining.send_training_log,
        identifier: crypto.randomUUID()
      }
      newerTrainings.push(newTraining)
    }
    // FORCE UPDATE
    setNewTrainings(newerTrainings, toggleUpdate)
  }

  const deleteSelection = (): void => {
    for (const selectedTraining of selectedTrainings) {
      onDelete(selectedTraining.id)
    }
    setSelectedTrainings([])
  }

  return (
    <>
      {selectedTrainings.length > 0 && (
        <div className='row margin-top'>
          <div className='col s12' style={{ display: 'flex' }}>
            <Button autowidth onClick={copySelectionToNewTrainingSessions}>
              <FontAwesomeIcon icon='edit' className='button-icon' />
              {I18n.t('components.trainings.button.copy_to_new_training_sessions')}
            </Button>
            <DeleteButton onClick={deleteSelection}>{I18n.t('components.trainings.button.delete')}</DeleteButton>
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col s12'>
          <TrainingSessionsTable
            tableData={schedules}
            updateTrainingProps={updateTrainingProps}
            handleSelectionChanged={onSelectionChanged}
            readOnly={readOnly}
          />
        </div>
      </div>
    </>
  )
}

export default ScheduledTrainingSessions
